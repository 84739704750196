import React, { useContext } from "react";
import Layout from "../../components/layout";
import GeneralAnchor from "../../components/general-anchor/general-anchor.component";
import { MenuContext } from "../../context";
import NewWindow from "../../assets/new_window.inline.svg";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";
import "../../styles/app.scss";

const Products = () => {
  const menuContext = useContext(MenuContext);

  const location = useLocation();
  if (typeof window !== "undefined" && window.utag) {
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: location.href,
      pageType: "product information",
      pageName: "products",
      pageSubCategory: "products",
      rawDataLayer: "10451v7",
    });
  }

  const handleClick = () => {
    if (typeof window !== "undefined" && window.utag) {
      triggerTealiumEvent({
        tealiumEvent: "link",
        pageUrl: location.href,
        eventCategory: "content",
        eventAction: "button",
        eventContent: "find out more",
        pageType: "product information",
        pageName: "products",
        pageSubCategory: "products",
        rawDataLayer: "10451v10",
      });
    }
  };

  return (
    <Layout title="Products">
      <section className="product-page page-section row">
        <div className="col-xs-12 col-lg-7">
          <h2>Products</h2>
          <p className="mb-20">
            Here you'll find details of the entire range of mortgages we have available via HSBC UK. Plus, you can read
            up on valuation fees, mortgage fees and eligibility for HSBC Premier and HSBC Advance mortgages.
          </p>
          <div className="product-block">
            <h3>Product Finder</h3>
            <p>Use our helpful tool to search our full mortgage range by feature or by product code.</p>
            <GeneralAnchor
              ariaLabel="Find out more"
              additionalClass="product-anchor-btn"
              href="/products/product-finder/"
              onClick={handleClick}
            >
              Find out more
            </GeneralAnchor>
          </div>
          <div className="product-block">
            <h3>Valuation fees</h3>
            <p>HSBC UK only offer a Standard Valuation or a Scottish Transcript as part of our application process.</p>
            <p>
              If you require a more detailed type of valuation report (i.e. Homebuyers or Buildings Survey) you can
              source this independently using the services of an appropriately qualified surveyor, details of which can
              be found via the{" "}
              <a href="https://www.ricsfirms.com/" target="_blank" inert={menuContext.isMenuOpen ? "true" : null}>
                RICS website
                <NewWindow style={{ marginBottom: "-3px", marginLeft: "3px" }} />
              </a>
              .
            </p>
          </div>
          <div className="informations">
            <p className="information usage">
              This website is for the use of FCA authorised mortgage intermediaries only.
            </p>
            <p className="information usage">
              If you reproduce any information contained in this website, to be used with or advise clients, you must
              ensure it follows the FCA's advising and selling standards.
            </p>
          </div>
        </div>
        <div className="right-side col-xs-12 col-lg-4 col-lg-offset-1"></div>
      </section>
    </Layout>
  );
};

export default Products;
